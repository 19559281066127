import {
  Fragment,
  useCallback,
  useEffect,
  useState,
  type CSSProperties,
  type KeyboardEvent,
} from "react";

import { useLogin, useUser } from "@vgno/account";
import { Button, Text } from "@vgno/core";
import { Cancel, Check, InfoActive } from "@vgno/icons";
import { capitalize, classnames } from "@vgno/utils";

import type { PollComponent } from "vg";

import styles from "./Poll.module.css";

type Statistics = Partial<PollComponent["statistics"]> & { answer?: string };

const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");

const getStoredStatistics = (
  subtype: string,
  id: string,
): Statistics | null => {
  try {
    const stored = JSON.parse(
      window.localStorage.getItem(`${subtype}-${id}`) || "null",
    );
    return stored;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getPercentages = (statistics?: Statistics, total?: number) => {
  const answers = statistics?.answers || {};

  if (!answers || !total) return {};

  let rest = 100;

  return Object.entries(answers).reduce(
    (acc, [key, votes], index, array) => {
      if (index === array.length - 1) {
        acc[key] = rest;
        return acc;
      }

      const percentage = parseInt(((parseInt(votes) / total) * 100).toFixed());
      acc[key] = percentage;
      rest -= percentage;
      return acc;
    },
    {} as Record<string, number>,
  );
};

const Image = (image: PollComponent["image"]) => {
  if (!image) return null;

  const { imageAsset } = image;

  const srcSet = imageAsset?.urls
    .map(({ url, width }) => `${url} ${width}w`)
    .join(", ");

  return (
    <img
      alt={image.caption?.value}
      className={styles.image}
      loading="lazy"
      sizes="(min-width: 580px) 580px, 100vw"
      src={imageAsset.urls[0].url}
      srcSet={srcSet}
    />
  );
};

const isValidStatistics = (
  statistics: Statistics | null,
): statistics is Statistics => {
  if (!statistics) return false;
  return "answers" in statistics && "lastUpdated" in statistics;
};

const shouldRevalidate = (lastUpdated?: string) => {
  if (!lastUpdated) return true;
  return new Date() > new Date(new Date(lastUpdated).getTime() + 5 * 60 * 1000);
};

const getUrl = (subtype: "poll" | "question", hash: string) => {
  if (typeof window === "undefined") return null;
  const urlObject = new URL(window.location.href);
  urlObject.hash = hash;
  if (subtype === "poll")
    urlObject.searchParams.set("utm_source", "login-flow-poll");
  if (subtype === "question")
    urlObject.searchParams.set("utm_source", "login-flow-question");
  return urlObject.toString();
};

const API_PROXY = import.meta.env.DEV
  ? "https://local.vg.no:3443/artikkel/api/poll"
  : "/artikkel/api/poll";

export const Poll = ({
  answers,
  fact,
  id,
  image,
  label,
  question,
  requiresLogin = true,
  subtype,
  ...props
}: PollComponent) => {
  const user = useUser();
  const { login } = useLogin({ origin: getUrl(subtype, `poll-${id}`) });
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [statistics, setStatistics] = useState<Statistics | undefined>(
    props.statistics,
  );
  const API = requiresLogin
    ? API_PROXY
    : "https://quiz-api.storytelling.schibsted.io";

  const revalidate = useCallback(
    async (overrideAnswer?: string) => {
      try {
        const response = await fetch(`${API}/answers/${id}`);
        const data = await response.json();

        setStatistics((current) => ({
          ...current,
          ...data,
          ...(overrideAnswer && { answer: overrideAnswer }),
          lastUpdated: new Date().getTime(),
        }));

        if (overrideAnswer) {
          return localStorage.setItem(
            `${subtype}-${id}`,
            JSON.stringify({
              ...data,
              answer: overrideAnswer,
              lastUpdated: new Date().getTime(),
            }),
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    [API, id, subtype],
  );

  const handleChange = useCallback(
    async (answer: string) => {
      if (requiresLogin && !user) {
        // Store answer in local state
        setStatistics({
          answer,
        });

        // Store answer in localStorage
        localStorage.setItem(
          `${subtype}-${id}`,
          JSON.stringify({
            answer,
          }),
        );

        return setShowLoginPrompt(true);
      }

      try {
        const response = await fetch(
          `${API}/answers/${id}/${answer}?withTotals=true`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            method: "PUT",
            mode: requiresLogin ? "same-origin" : "cors",
          },
        );

        if (response.status === 405) {
          revalidate(answer);
          return;
        }

        if (!response.ok) {
          throw response;
        }

        const data: Statistics = {
          ...(await response.json()),
          answer,
          lastUpdated: new Date().getTime(),
        };

        setStatistics(data);
        localStorage.setItem(`${subtype}-${id}`, JSON.stringify(data));
      } catch (error) {
        console.error(error);
      }
    },
    [id, subtype, requiresLogin, user, API, revalidate],
  );

  /**
   * Revalidate server data on the client
   */
  useEffect(() => {
    if (requiresLogin && !user) return;

    setStatistics((current) => {
      if (!current?.answers || shouldRevalidate(current.lastUpdated)) {
        revalidate();
      }
      return current;
    });
  }, [id, revalidate, requiresLogin, user]);

  useEffect(() => {
    if (requiresLogin && user === undefined) return;

    const { answer, answers, lastUpdated } =
      getStoredStatistics(subtype, id) || {};

    // If user is logged in and has answered, submit answer
    if (requiresLogin && user && answer && !answers) {
      handleChange(answer);
      setShowLoginPrompt(false);
    }

    // If user is not logged in and has answered, prompt user to login
    if (requiresLogin && !user && answer && !answers) {
      setStatistics({ answer });
      setShowLoginPrompt(true);
    }

    if (isValidStatistics({ answers, lastUpdated })) {
      setStatistics((state) => {
        if (
          !state?.lastUpdated ||
          (lastUpdated && lastUpdated > state?.lastUpdated)
        ) {
          return {
            answer,
            answers,
            lastUpdated,
          };
        }

        return { ...state, answer };
      });
    }
  }, [id, subtype, user, requiresLogin, handleChange]);

  const handleKeyPress = (event: KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === "Enter") {
      event.currentTarget.click();
    }
  };

  const answer = statistics?.answer;

  const votes = statistics?.answers
    ? Object.values(statistics?.answers).reduce(
        (acc, votes) => acc + parseInt(votes),
        0,
      )
    : 0;

  const percentages = getPercentages(statistics, votes);

  const showStatistics = requiresLogin
    ? Boolean(user && answer && votes)
    : Boolean(answer && votes);

  return (
    <section
      className={classnames(
        "font-inter",
        "layout-component",
        "layout-normal",
        "layout-padded",
        styles.poll,
      )}
      data-theme="accent"
      data-track-element-type={capitalize(subtype)}
      data-track-id={`${subtype}:${id}`}
      data-track-impression
      data-track-name={question}
      id={`poll-${id}`}
    >
      <form
        className={classnames(
          "label-medium label-primary background-primary",
          answer && styles.submitted,
          styles.form,
        )}
        data-subtype={subtype}
      >
        {image && <Image {...image} />}
        <fieldset className={styles.fieldset}>
          <legend
            className={classnames("font-weight-semi-bold", styles.legend)}
          >
            <span className="label-primary">
              {label
                ? label
                : subtype === "poll"
                  ? question?.trim()?.endsWith("?")
                    ? null
                    : "Hva tenker du?"
                  : "Test deg selv"}
            </span>
            <span className="title-medium">{question}</span>
          </legend>
          {answers.map(({ correct, value: label }, index) => (
            <Fragment key={index}>
              <input
                checked={answer === String(index)}
                disabled={showStatistics}
                hidden
                id={`${String(index)}-${id}`}
                name={id}
                onChange={(e) => handleChange(e.target.value)}
                type="radio"
                value={String(index)}
              />
              <label
                className={classnames(
                  showStatistics && styles.disabled,
                  answer === String(index) && styles.selected,
                  styles.label,
                )}
                data-track-id={`${subtype}:${id}:${index}`}
                data-track-name={label}
                {...(subtype === "question" &&
                  user && {
                    "data-correct": correct ?? false,
                  })}
                htmlFor={`${String(index)}-${id}`}
                onKeyUp={handleKeyPress}
                style={
                  {
                    "--percentage": `${showStatistics ? (percentages[index] ?? 0) : 0}%`,
                  } as CSSProperties
                }
                tabIndex={0}
                onPointerUp={() => null}
              >
                <span
                  className={classnames(
                    "font-weight-semi-bold background-primary",
                    styles.letter,
                  )}
                >
                  {subtype === "poll" || !user || !answer ? (
                    <>{alphabet[index]}</>
                  ) : (
                    <>{correct ? <Check /> : <Cancel />}</>
                  )}
                </span>
                <span
                  className={classnames(
                    styles.value,
                    answer === String(index) && "font-weight-semi-bold",
                  )}
                >
                  {label}
                </span>
                {showStatistics && (
                  <span className={styles.percentage}>
                    {percentages[index] ?? 0}%
                  </span>
                )}
              </label>
            </Fragment>
          ))}
          {showLoginPrompt && (
            <footer className={classnames(!fact && "pt-xs", styles.footer)}>
              <p className="font-weight-semi-bold">
                Ups! Du er visst ikke logget inn.
                <small
                  className={classnames(
                    styles.small,
                    "label-small",
                    "font-weight-regular",
                  )}
                >
                  {subtype === "poll"
                    ? "For å unngå misbruk av tjenesten, må du være logget inn for å stemme. Det er helt gratis."
                    : "For å unngå misbruk av tjenesten, må du være logget inn for å svare. Det er helt gratis."}
                </small>
              </p>
              <Button
                aria-label={
                  subtype === "poll"
                    ? "Logg inn for å stemme"
                    : "Logg inn for å svare"
                }
                data-track-id={`${subtype}:${id}:login`}
                data-track-name="Login Button"
                onClick={login}
                shape="capsule"
                style="filled"
                onPointerUp={() => null}
              >
                {subtype === "poll"
                  ? "Logg inn for å stemme"
                  : "Logg inn for å svare"}
              </Button>
            </footer>
          )}
          {showStatistics && (
            <footer className={classnames(!fact && "pt-xs", styles.footer)}>
              {fact && (
                <Text
                  className={styles.fact}
                  component={fact}
                  styles={styles}
                />
              )}
              <p className="font-weight-semi-bold">
                {votes}{" "}
                {subtype === "poll"
                  ? `stemme${votes! > 1 ? "r" : ""}`
                  : "svar avgitt"}
              </p>
              {subtype === "poll" && (
                <>
                  <Button
                    aria-label="Vis informasjon om avstemningen"
                    className={styles.toggle}
                    onClick={() => setShowDisclaimer(!showDisclaimer)}
                  >
                    <InfoActive />
                  </Button>
                  {showDisclaimer && (
                    <p className={styles.disclaimer}>
                      Resultatet viser hva VGs lesere har svart, og er ikke
                      nødvendigvis representativt for flertallet i Norge. Ditt
                      svar er anonymt.
                    </p>
                  )}
                </>
              )}
            </footer>
          )}
        </fieldset>
      </form>
    </section>
  );
};
