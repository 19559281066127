import { useFetch } from "@vgno/hooks";
import { Teaser } from "@vgno/teasers/Teaser";
import { classnames, fetcher } from "@vgno/utils";

import type { Article } from "@vgno/article";

import styles from "./AffiliateArticles.module.css";

const makeUrl = ({
  articleId,
  isFallback,
  teaserUrl,
}: {
  articleId: string;
  isFallback: boolean;
  teaserUrl: string;
}): string => {
  const url = new URL(teaserUrl);
  url.searchParams.set("utm_source", "vg-article");
  url.searchParams.set("utm_medium", "AffiliateArticles");
  url.searchParams.set("utm_campaign", isFallback ? "fallback" : "related");
  url.searchParams.set("utm_content", articleId);
  return url.toString();
};

interface Props {
  articleId: string;
}

type TeaserWithFallback = Article["teaser"] & { isFallback: boolean };

const AffiliateArticles = ({ articleId }: Props) => {
  const { data } = useFetch<TeaserWithFallback[]>(
    `/artikkel/api/affiliate/${articleId}`,
    fetcher,
  );

  return (
    <section className={styles.articles} data-nosnippet>
      <h3
        className={classnames(
          styles.title,
          "font-inter font-weight-semi-bold title-large",
        )}
      >
        Kommersielt innhold
      </h3>

      {data?.map((teaser, index) => (
        <Teaser
          {...teaser}
          key={teaser.id}
          tracking={{
            bundlePosition: index + 1,
            primaryPosition: index + 1,
            elementType: `${teaser.isFallback ? "Fallback" : "Related"} Affiliate Articles`,
          }}
          fetchPriority="low"
          kicker={"Annonse"}
          newsroom={teaser.newsroom}
          size="small"
          url={makeUrl({
            articleId,
            isFallback: teaser.isFallback,
            teaserUrl: teaser.links.canonicalUrl,
          })}
        />
      ))}
    </section>
  );
};

export default AffiliateArticles;
