var l = Object.defineProperty;
var o = (i, t, e) => t in i ? l(i, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : i[t] = e;
var n = (i, t, e) => o(i, typeof t != "symbol" ? t + "" : t, e);
import { capitalize as a, toFriendly as c } from "@vgno/utils";
class p extends HTMLElement {
  constructor() {
    super();
    n(this, "interval", null);
    n(this, "timeElement", null);
  }
  update(e) {
    if (!this.timeElement)
      throw new Error("No time element found");
    const s = this.timeElement.dateTime;
    if (!s)
      throw new Error("No timestamp attribute found");
    const r = new Date(s).getTime(), m = a(
      c(r, void 0, e)
    );
    if (this.timeElement.querySelector("span")) {
      this.timeElement.querySelector("span").textContent = m;
      return;
    }
    this.timeElement.textContent = m;
  }
  connectedCallback() {
    this.timeElement = this.querySelector("time");
    const e = this.options;
    this.update(e), this.interval = setInterval(() => this.update(e), 1e3);
  }
  disconnectedCallback() {
    this.interval && clearInterval(this.interval);
  }
  get options() {
    const { options: e } = this.dataset;
    return e ? JSON.parse(e) : {};
  }
}
function h() {
  customElements.get("friendly-timestamp") || customElements.define("friendly-timestamp", p);
}
export {
  p as FriendlyTimestampElement,
  h as registerFriendlyTimestampElement
};
